body{
    width: 100vw;
    height: 100wh;
    display: flex;
}
nav#topBar, nav#bottomBar{
    flex: 0 0 auto;
}
.leaflet-top{
    top: 50px !important;
}
.dropdown-menu.show{
    /*top: 0px;*/
    right: 0px;
    width: 100%
}
.offcanvas.slideMenu .offcanvas-header{
    border-bottom: 1px solid #fff;
}
.offcanvas.slideMenu .offcanvas-body .list-group .list-group-item{
    border: 0px solid transparent !important;
    padding-bottom: 20px;
    font-size: 24px;
}
.list-group.userMenu .list-group-item{
    border: 0px solid transparent !important;
    padding-top:15px;
    padding-bottom: 15px;
    font-size: 24px;
}

.mobileContainer{
    margin-top:60px;
    width: 100vw;
}


.adsbygoogle {
    min-height: 125px;
    background-color: #fff;
}