@import 'bootstrap/dist/css/bootstrap.min.css';
.text-right{ text-align: right !important;}
ul.timeline-3 {
    list-style-type: none;
    position: relative;
  }
  ul.timeline-3:before {
    content: " ";
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 10px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }
  ul.timeline-3 > li {
    margin: 5px 0;
    padding-left: 0px;
  }
  ul.timeline-3 > li > span.badge{
    padding-left: 10px;
  }
  ul.timeline-3 > li:before {
    content: " ";
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 0px;
    width: 20px;
    height: 20px;
    z-index: 400;
    margin-top: 3px;
  }
  ul.timeline-3 > li.nw:before{
    background: rgba(87,0,0,1);
    border: 3px solid rgba(255,0,0,1);
  }
  ul.timeline-3 > li.w:before{
    background: rgba(0,87,0,1);
    border: 3px solid rgba(0,255,0,1);
  }

#root{
  width:100%;
}
.collapse-content{
  display:flex;
  width:100%;
}