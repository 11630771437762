[data-bs-theme=dark] { 
    --bs-body-bg: #18191a !important; 
    --card-bg:#242526;
}

body{ width: 100% }

.mt-base{
    margin-top: calc(55px + 15px);
}

.leaflet-top{
    top: 55px !important;
}
#mapPicture .leaflet-control-attribution{
    display: none !important;
}

.desktopContainer.container-fluid{
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.box{
    transition:all 0.5s ease-in-out;
}
.box:hover{
    background-color: #4e5052;
}

.dropdown-menu[data-bs-popper]{
    left: inherit !important;
    right: 0px !important;
}

.profile-cover{
    padding-left: 0px ;
    padding-right: 0px;
}
.profile-action-row{
    margin-left: 0px;
    margin-right: 0px;
}
.avatar{
    background-color: #fff;
    object-fit: cover;
}
.fb-styled-card {
    background-color: var(--card-bg);
}
.fb-styled-card.details{
    padding: 10px;
    border: 0px solid transparent;
}
.fb-style-title{
    font-weight: bold;
    font-size: 18px;
}
.infoItem{
    margin-left: 15px;
}
.profile-thumb{
    border-radius: 50%;
    object-fit: cover;
    width: 40px;
    height: 40px;
}
.fb-styled-link{
    color:#fff;
    text-decoration: none;
    font-weight: bold;
}
.fb-styled-meta{
    font-size: 12px;
}

.btn-fbstyle {
    --bs-btn-color: #fff;
    --bs-btn-bg: #242526;
    --bs-btn-border-color: #242526;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3a3b3c;
    --bs-btn-hover-border-color: #3a3b3c;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3a3b3c;
    --bs-btn-active-border-color: #3a3b3c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #242526;
    --bs-btn-disabled-border-color: #242526;
}
.form-control.fbstyle{
    background-color: #3a3b3c;
    border: 0px solid #3a3b3c;
    border-radius: '35px';
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.form-control.fbstyle:hover, .form-control.fbstyle.fbstyle.active{
    background-color: #4e4f50;
}
.btn-fb-field-button, .fb-field-button{
    background-color: #3a3b3c;
    color: #74b5ff;
}
.btn-fb-field-button:hover, .fb-field-button:hover{
    background-color: #4e4f50;
    color: #81bcfe;
}
.btn-fb-field-button:disabled, .fb-field-button:disabled{
    --bs-btn-disabled-opacity: 1;
    border: 1px solid transparent;
    background-color: #3a3b3c;
    color: #757576;
    cursor: not-allowed !important;
}

.btn-fb-header-btn{
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border: 0px solid transparent;
    border-bottom: 3px solid transparent;
    border-radius: 0px;
    margin-top: -10px;
    margin-bottom: -4px;
    font-weight: bold;
}
.btn-fb-header-btn:hover{
    background-color: #3a3b3c;
}
.btn-fb-header-btn.active{
    color:#0866ff !important;    
    border-bottom: 3px solid #0866ff !important;
}
.fb-styled-comment-box{
    background-color: #3a3b3c;
}
.fb-styled-comment-box > a{
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}
.fb-style-span-link{
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    cursor: pointer;
}

.modal-scrolled-body{
    max-height: 650px;
    overflow-y: auto;
}
.modal-comments .modal-footer{
    display:block !important;
}

.youtubeVideoEmbed{
    margin-top: 10px;
}

.fb-style-list, .fb-style-list .list-group-item{
    border: 0px solid transparent !important;
    border-radius: 0px !important;
}
.fb-style-list .list-group-item{
    border-radius: 0px !important;
    padding-top: 15px;
    padding-bottom: 15px;
}
.fb-style-list .list-group-item:hover{
    background-color: #3a3b3c;
}
.onlineUserRow{
    padding-top:5px;
    padding-bottom: 5px;
    border-radius: 5px;
}
.onlineUserRow:hover{
    background-color: #3a3b3c;
}
.onlineMarker{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    position: relative;
    bottom: 10px;
    left: 25px;
}


.fb-styled-card .fb-style-list .list-group-item{
    background-color: var(--card-bg);
}
.fb-styled-card .fb-style-list .list-group-item.active{
    color: #0866ff;
}
.fb-styled-card .fb-style-list .list-group-item:hover{
    background-color: #3a3b3c;
}